import { Address } from 'viem'

import tryRequest, { RequestOptions } from './tryRequest'
import {
  PublicGetInviteCodeParamsSchema,
  PublicGetInviteCodeResponseSchema,
} from './types/public.get_invite_code'

export default async function fetchInviteCode(wallet: Address, options?: RequestOptions) {
  return tryRequest<PublicGetInviteCodeParamsSchema, PublicGetInviteCodeResponseSchema>(
    '/public/get_invite_code',
    { wallet },
    options
  )
}
