import { Address } from 'viem'

import tryRequest, { RequestOptions } from './tryRequest'
import {
  PublicGetPointsParamsSchema,
  PublicGetPointsResponseSchema,
} from './types/public.get_points'

export default async function fetchPoints(
  program: string,
  wallet: Address,
  options?: RequestOptions
) {
  return tryRequest<PublicGetPointsParamsSchema, PublicGetPointsResponseSchema>(
    '/public/get_points',
    { program, wallet },
    options
  )
}
