import { SECONDS_IN_MINUTE } from '@lyra/core/constants/time'
import useSWR from 'swr'

import { AirdropBonusId } from '../constants/points'
import useAuth from './useAuth'

type Response = Partial<
  Record<
    AirdropBonusId,
    {
      isComplete: boolean
      isEligible: boolean
      deposited: number
      volume: number
      numComplete: number
    }
  >
>

const fetcher = async (): Promise<Response | null> => {
  const res = await fetch('/api/check-bonus', { method: 'POST' })
  if (!res.ok) {
    return null
  }
  const data = await res.json()
  return data
}

export default function usePointsBonuses() {
  const { user } = useAuth()
  const address = user ? user.address : undefined
  return useSWR(
    address ? ['PointsBonuses', address] : null,
    ([_, address]) => (address ? fetcher() : null),
    { refreshInterval: 5 * SECONDS_IN_MINUTE * 1000, revalidateOnFocus: false } // 5 minutes
  )
}
