'use client'

import BodyText from '@lyra/core/components/BodyText'
import Button from '@lyra/core/components/Button'
import Link from '@lyra/core/components/Link'
import Modal from '@lyra/core/components/Modal'
import Section from '@lyra/core/components/Section'
import isDeepEquals from '@lyra/core/utils/isDeepEquals'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useIntercom } from 'react-use-intercom'

import { LOCAL_STORAGE_BLOCKED_KEY } from '../constants/localStorage'
import { PageId } from '../constants/pages'
import {
  LevelConfig,
  POINTS_UPDATE_INTERVAL_MS,
  PointsData,
  PointsEpoch,
} from '../constants/points'
import { baseUrl } from '../constants/urls'
import { User } from '../constants/user'
import useBoolLocalStorage from '../hooks/local_storage/useBoolLocalStorage'
import useAuth from '../hooks/useAuth'
import useOrderbookTimestamp from '../hooks/useOrderbookTimestamp'
import usePointsBonuses from '../hooks/usePointsBonuses'
import { getPagePath } from '../utils/pages'
import {
  fetchPointsData,
  getLevelFromPoints,
  getPointsEpochForTimestamp,
  tryFetchInviteCode,
} from '../utils/points'
import { roundTimestampToLastInterval } from '../utils/time'

type Props = {
  inviteCode: string | undefined
  children?: React.ReactNode
} & PointsData

export type PointsContext = {
  epoch: PointsEpoch
  level?: LevelConfig
  shareableInviteCode?: string
  shareableInviteLink?: string
  lastUpdateTimestamp: number
} & PointsData

const EMPTY_POINTS_DATA: PointsData = {
  isRegistered: false,
}

const EMPTY_EPOCH: PointsEpoch = {
  round: 1,
  label: '',
  startTimestamp: 0,
  endTimestamp: 0,
  levels: [
    {
      id: 1,
      cutoff: 0,
      level: 1,
      sublevel: 1,
      levelName: 'NPC',
      color: 'gray',
    },
  ],
}

export const PointsContext = React.createContext<PointsContext>({
  isRegistered: false,
  epoch: EMPTY_EPOCH,
  lastUpdateTimestamp: 0,
})

const POLL_POINTS_MS = 30_000 // 30 seconds

export default function PointsProvider({ children, inviteCode, ...initialPointsData }: Props) {
  const { isAuthenticated, user } = useAuth()
  const address = user?.address

  const [_pointsData, setPointsData] = useState<PointsData>(initialPointsData)
  const pointsData = isAuthenticated ? _pointsData : EMPTY_POINTS_DATA

  const [shareableInviteCode, setShareableInviteCode] = useState(inviteCode)

  const { getTimestamp } = useOrderbookTimestamp()

  const epoch = useMemo(() => getPointsEpochForTimestamp(getTimestamp()), [getTimestamp])

  const level = useMemo(
    () => (pointsData.isRegistered ? getLevelFromPoints(epoch, pointsData.totalPoints) : undefined),
    [pointsData, epoch]
  )

  const prevPoints = useRef(initialPointsData?.points)
  const [lastUpdateTimestamp, setLastUpdateTimestamp] = useState<number>(
    roundTimestampToLastInterval(getTimestamp(), POINTS_UPDATE_INTERVAL_MS)
  )

  const mutatePoints = useCallback(async (): Promise<PointsData> => {
    if (address) {
      const pointsData = await fetchPointsData(epoch, address)
      setPointsData(pointsData)

      const points = pointsData?.points

      if (points) {
        // update estimate for last updated points timestamp
        if (!prevPoints.current || !isDeepEquals(points, prevPoints.current)) {
          prevPoints.current = points
          setLastUpdateTimestamp(
            roundTimestampToLastInterval(getTimestamp(), POINTS_UPDATE_INTERVAL_MS)
          )
        }
      }

      return pointsData
    } else {
      return EMPTY_POINTS_DATA
    }
  }, [epoch, address, getTimestamp])

  // mutate points every 30 seconds
  // TODO: @earthtojake useSWR for points, remove provider
  useEffect(() => {
    if (!isAuthenticated) {
      return
    }
    mutatePoints()
    const interval = setInterval(mutatePoints, POLL_POINTS_MS)
    return () => clearInterval(interval)
  }, [mutatePoints, isAuthenticated])

  useEffect(() => {
    // Reset points state
    if (!user) {
      setPointsData(EMPTY_POINTS_DATA)
      setShareableInviteCode(undefined)
    }
    const fetchInviteCode = async (user: User) => {
      const inviteCode = await tryFetchInviteCode(user.address)
      setShareableInviteCode(inviteCode)
    }
    if (user) {
      fetchInviteCode(user)
    }
  }, [user])

  // sets up global polling for points bonuses
  usePointsBonuses()

  const shareableInviteLink = useMemo(
    () => (shareableInviteCode ? `${baseUrl}/invite/${shareableInviteCode}` : undefined),
    [shareableInviteCode]
  )

  const value = useMemo(() => {
    return {
      ...pointsData,
      shareableInviteCode,
      shareableInviteLink,
      lastUpdateTimestamp,
      epoch,
      level,
    }
  }, [pointsData, epoch, level, lastUpdateTimestamp, shareableInviteCode, shareableInviteLink])

  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [isPopupSeen, setIsPopupSeen] = useBoolLocalStorage(LOCAL_STORAGE_BLOCKED_KEY)

  const { show } = useIntercom()

  const isBlocked = value.isBlocked

  useEffect(() => {
    if (isBlocked && !isPopupOpen && !isPopupSeen) {
      setIsPopupSeen(true)
      setIsPopupOpen(true)
    }
  }, [isBlocked, isPopupOpen, isPopupSeen, setIsPopupSeen])

  return (
    <PointsContext.Provider value={value}>
      {/* TODO: @earthtojake remove post-TGE for better system */}
      {isPopupOpen ? (
        <Modal isLarge title="Account Flagged" onClose={() => setIsPopupOpen(false)}>
          <Section.XStack>
            <BodyText color="secondary">
              Your wallet has been flagged for trading activity that violates our{' '}
              <Link
                label="Terms of Use"
                href={getPagePath({ page: PageId.TermsOfUse })}
                target="_blank"
              />
              . Your DRV points and additional rewards are blocked until further notice. If you
              believe is an error, please contact support.
            </BodyText>
          </Section.XStack>
          <Section.XStack>
            <Button size="lg" label="Contact Support" color="amber" width="100%" onPress={show} />
          </Section.XStack>
        </Modal>
      ) : null}
      {children}
    </PointsContext.Provider>
  )
}
